<template>
  <div class="uploader">
    <label class="uploader__label" :for="id">{{ placeholder }}</label>
    <div class="uploader__image" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <div class="uploader__input">
        <input
          class="uploader__input-input"
          type="file"
          :name="id"
          :id="id"
          @change="onAddChange({ element: $event })"
          ref="fileAdd"
          :accept="typesString"
          multiple
          :disabled="disabled"
        />
        <div class="uploader__input-label">
          <p class="uploader__input-label-text">
            Drag & Drop your file(s) here or
            <label class="uploader__input-label-button" :for="id"> browse </label>
          </p>
          <p class="uploader__input-label-description">
            <span>{{ acceptString }}, </span>
            max. file size {{ size }} MB
          </p>
        </div>
      </div>
    </div>
    <div class="uploader__files" v-if="files.length">
      <div class="uploader__file" v-for="(file, index) in files" :key="`file-${index}`">
        <p class="uploader__file-name">{{ file.name }}</p>
        <p class="uploader__file-size">{{ parseFloat(file.size / (1024 * 1024)).toFixed(1) }} MB</p>
        <IconClose
          class="uploader__file-button"
          color="#fff"
          @click.native="removeFile(index)"
        ></IconClose>
      </div>
    </div>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconClose from "@/components/icons/IconCloseBold.vue";
import { mapActions } from "vuex";
export default {
  props: {
    id: { type: [String, Number], default: "upload" },
    placeholder: { type: String, default: "Uploader" },
    accept: {
      type: Array,
      default() {
        return ["png", "jpeg", "jpg", "pdf", 'webp'];
      },
    },
    size: { type: [Number, String], default: "15" },
    file: { type: [File, Array], require: true },
    disabled: { type: Boolean, default: false },
    canRemove: { type: Boolean, default: true },
  },
  components: {
    IconButton,
    IconEdit,
    IconPlus,
    IconClose,
  },
  data() {
    return {
      types: "",
      acceptString: "",
      typesString: "",
      files: [],
    };
  },
  created() {
    if (Array.isArray(this.file)) this.files = this.file;
    else this.files.push(this.file);
    this.types = this.accept.map(el => {
      return `.${el}`;
    });
    this.typesString = this.types.join(", ");
    this.acceptString = this.accept.join(", ");
  },
  watch: {
    file(val) {
      if (Array.isArray(val)) this.files = val;
      else this.files = [val];
    },
  },
  methods: {
    checkSize(val) {
      const size = parseFloat(val.size / (1024 * 1024)).toFixed(2);
      let correct = false;
      if (+size > +this.size) {
        this.$toasted.show(`Please select image ${val.name} size less than ${this.size} MB`, {
          duration: 3000,
          type: "error",
        });
      } else {
        correct = true;
      }
      return correct;
    },

    checkType(val) {
      let correctType = false;
      this.types.map(type => {
        if (val.name.includes(type)) {
          correctType = true;
        }
      });
      if (!correctType) {
        this.$toasted.show(`Incorrect type ${val.name}. Select from ${this.acceptString}`, {
          duration: 3000,
          type: "error",
        });
      }
      return correctType;
    },

    async onAddChange({ element, dropEl }) {
      let files = [];
      for (let el of element.target.files) {
        const size = this.checkSize(el);
        const type = this.checkType(el);
        if (size && type) {
          files.push(el);
        }
      }
      this.files = this.files.concat(files);
      this.$emit("input", { files: this.files });
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.$emit("input", { files: this.files });
    },
    drop(event) {
      event.preventDefault();
      const eventEl = { target: { files: event.dataTransfer.files } };
      this.onAddChange({ element: eventEl, dropEl: event });
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {},
  },
};
</script>
<style lang="scss" scoped>
.uploader {
  &--full-width {
    width: 100%;
  }
  &--lg {
    width: 100%;
    .uploader__image {
      height: 10.5rem;
      border-radius: 8px;
      border: 1px solid $grey;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  &--error {
    border-color: $error;
  }
  &__label {
    display: block;
    font-family: $fontFrutiger;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.27px;
    color: $white;
    margin-bottom: 10px;
  }
  &__image {
    position: relative;
    height: 100%;
    &--selected {
    }
    &--active {
    }
  }
  &__input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 2;
    &-input {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      z-index: -1;
    }
    &-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      &-text {
        font-family: $fontFrutigerNeue;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: -0.4px;
        color: rgba($color: $white, $alpha: 0.7);
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
      }
      &-description {
        font-family: $fontFrutigerNeue;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: -0.3px;
        color: $white;
        span {
          font-family: $fontFrutigerNeue;
          font-size: 1.8rem;
          font-weight: 300;
          line-height: 1.5;
          letter-spacing: -0.3px;
          color: $white;
          text-transform: uppercase;
        }
      }
      &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        .button {
          margin: 0 8px;
        }
      }
      &-button {
        font-family: $fontFrutigerNeue;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.4px;
        color: $white;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  &__files {
    margin-top: 3rem;
  }
  &__file {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 15px;
    background: rgba($color: $darkGrey, $alpha: 0.3);
    border: 0.4px solid rgba($color: $white, $alpha: 0.4);
    border-radius: 5px;
    height: 5rem;
    margin-top: 10px;
    &-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      overflow: hidden;
      width: 100%;
      font-family: $fontFrutiger;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.27px;
      color: $white;
    }
    &-size {
      flex-shrink: 0;
      font-family: $fontFrutiger;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.33px;
      color: rgba($color: $white, $alpha: 0.7);
      margin-right: 2rem;
    }
    &-button {
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
  }
}
</style>
